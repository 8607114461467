import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DamsGridItem} from "../operation-view-dams-dialog.component";
import {DamsService} from "../../../core/dams.service";
import {MatIcon} from "@angular/material/icon";
import {MatButton, MatIconButton} from "@angular/material/button";
import {CmsApiService} from "../../../core/cms-api.service";
import {TranslateModule} from "@ngx-translate/core";
import {MatCheckbox} from "@angular/material/checkbox";

interface DamsConfirmationItem extends DamsGridItem {
    copyright?: any;
    copyright_clauses?: any[];
    description?: string;
    file_size?: number;
    keywords?: string[];
    licenses?: string[];
    mime_type?: string;
    places?: any[];
    producers?: any[];
    production_date?: string;
    resolution?: string;
}

interface DamsSuggestionItem {
  id: string;
  label: string;
}

@Component({
  selector: 'app-operation-view-dams-dialog-edit-item',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    TranslateModule,
    MatButton,
    MatCheckbox
  ],
  templateUrl: './operation-view-dams-dialog-edit-item.component.html',
  styleUrl: './operation-view-dams-dialog-edit-item.component.scss'
})
export class OperationViewDamsDialogEditItemComponent implements OnInit{
  @Input() item: DamsGridItem;
  @Input() image: any;

  @Output() removed = new EventEmitter();

  extendedData: DamsConfirmationItem = null;
  suggestion: DamsSuggestionItem = null;

  constructor(
    private readonly damsApi: DamsService,
    private readonly cms: CmsApiService
  ) {}

  ngOnInit() {
    this.damsApi.getMetaData(this.item.id).then((result: any) => {
      this.extendedData = {
        ...this.item,
        copyright: result.copyright,
        copyright_clauses: result.copyright_clauses,
        description: result.description,
        file_size: result.file_size,
        keywords: result.keywords,
        licenses: result.licenses,
        mime_type: result.mime_type,
        places: result.places,
        producers: result.producers,
        production_date: result.production_date,
        resolution: result.resolution
      }

      return this.cms.getDamsMetadataSuggestions({metadata: [result]});
    }).then((suggestions) => {
      console.log('suggestions: ', suggestions);

      this.searchForSuggestion();
    })
  }

  clearSuggestion() {
    this.suggestion = null;
  }

  searchForSuggestion() {
    this.suggestion = {
      id: 'someid',
      label: this.item.created_by
    }
  }

  protected readonly DamsService = DamsService;
}
