<div class="dams-dialog">
  <div class="dams-dialog__header">
    <span class="dams-dialog__header-label">
      {{ operationContainer.currentOperation.menu_title | translate }}
    </span>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>
        clear
      </mat-icon>
    </button>
  </div>

  @if (step === 1) {
    <div class="dams-dialog__search">
      <mat-form-field class="dams-dialog__form-field">
        <input
          matInput
          class="dams-dialog__input"
          [placeholder]="'TRANS__DAMS_DIALOG__SEARCH_DAMS' | translate"
          [(ngModel)]="query"
        />

        <mat-icon matSuffix>
          search
        </mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'TRANS__DAMS_DIALOG__MEDIA_TYPE' | translate }}
        </mat-label>
        <mat-select (valueChange)="selectType($event)" [value]="selectedType">
          <mat-option value="all">
            {{ 'TRANS__DAMS_TYPE__ALL' | translate }}
          </mat-option>
          @for (type of damsService.getAvailableTypes(); track $index) {
            <mat-option [value]="type.value">
              {{ type.label | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="dams-dialog__result-header">
    <span class="dams-dialog__result-label">
      {{ totalCount() }} {{ 'TRANS__DAMS_DIALOG__RESULT_COUNT' | translate }}
    </span>
      <!-- keydown null to satisfy the almighty sonarlint god -->
      <div matRipple class="dams-dialog__clear" (click)="clearSelected()" [ngClass]="{ 'dams-dialog__clear--hidden' : selectedItems.length === 0 }" (keydown)="null">
        <span class="dams-dialog__clear-label">
          @if (selectedItems.length === 1) {
            {{ selectedItems.length }} {{ 'TRANS__DAMS_DIALOG__SELECTED_ONE' | translate }}
          }
          @else {
            {{ selectedItems.length }} {{ 'TRANS__DAMS_DIALOG__SELECTED_MORE' | translate }}
          }
        </span>
        <mat-icon class="dams-dialog__clear-icon">
          cancel
        </mat-icon>
      </div>

      <button mat-button (click)="toggleSorting()">
        <mat-icon matPrefix>
          sort_by_alpha
        </mat-icon>
        {{ 'TRANS__DAMS_DIALOG__SORTING' | translate }}
      </button>

      <mat-paginator
        (page)="pageChange($event)"
        [hidePageSize]="true"
        [length]="totalCount()"
        [pageIndex]="pageNumber"
        pageSize="40"
      ></mat-paginator>
    </div>

    @if(loading) {
      <div class="dams-dialog__result-loader">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </div>
    }

    @if(!loading && searchResults().length > 0) {
      <div class="dams-dialog__result">
        @for(item of searchResults(); track $index) {
          <app-operation-view-dams-dialog-item
            (batchToggleItems)="batchToggleItems($event)"
            [index]="$index"
            [item]="item"
            [image]="getImage(item.dmsf_id)"
          />
        }
      </div>
    }

    @if(!loading && searchResults().length === 0) {
      <div class="dams-dialog__result-loader">
      <span>
        {{ 'TRANS__DAMS_DIALOG__NO_RESULTS' | translate }}
      </span>
      </div>
    }
  }

  @if (step === 2) {
    <div class="dams-dialog__confirmation">
      @for(item of selectedItems; track $index) {
        <app-operation-view-dams-dialog-edit-item
          [item]="item"
          [image]="getImage(item.dmsf_id)"
          (removed)="removeSelectedItem(item)"
        />
      }
    </div>
  }

  <div class="dams-dialog__footer">
    <div class="dams-dialog__footer-label">
      <span class="dams-dialog__step-label">
        {{ 'TRANS__DAMS_DIALOG__STEP' | translate }} {{step}}/2
      </span>
      <span class="dams-dialog__step-description-label">
        {{ 'TRANS__DAMS_DIALOG__STEP_' + step + '_DESCRIPTION' | translate}}
      </span>
    </div>

    <button mat-button (click)="closeDialog()">
      {{ 'TRANS__DAMS_DIALOG__CANCEL' | translate }}
    </button>

    @if (step !== 1) {
      <button mat-stroked-button (click)="previousStep()">
        {{ 'TRANS__DAMS_DIALOG__BACK' | translate }}
      </button>
    }

    <button mat-flat-button (click)="nextStep()" [disabled]="selectedItems.length === 0">
      {{ 'TRANS__DAMS_DIALOG__CONTINUE' | translate }}
    </button>
  </div>
</div>
