import {Directive, ElementRef, EventEmitter, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ResizeHandleComponent} from './resize-handle/resize-handle.component';

@Directive({
  selector: '[appResizable]',
  standalone: true
})
export class ResizableDirective implements OnInit {

  @Output() appResizableAfterResized = new EventEmitter<void>();

  constructor(
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit() {
    let componentRef = this.viewContainerRef.createComponent(ResizeHandleComponent);
    componentRef.location.nativeElement.addEventListener('mousedown', this.handleMouseDown);
    this.el.nativeElement.appendChild(componentRef.location.nativeElement);
  }

  handleDrag = (e: MouseEvent) => {
    // negate the movement as we are scaling from right to left
    const delta = -e.movementX;
    this.el.nativeElement.style.width = `${this.el.nativeElement.offsetWidth + delta}px`;
  }

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleDrag);
    document.removeEventListener('mouseup', this.handleMouseUp);
    this.appResizableAfterResized.emit();
  }

  handleMouseDown = () => {
    document.addEventListener('mousemove', this.handleDrag);
    document.addEventListener('mouseup', this.handleMouseUp);
  }
}
