<ng-template #gridListSmall let-items="items" let-count="count" let-onClick="onClick" let-onShowMore="onShowMore" let-onShowLess="onShowLess" let-loading="loading">
  <div class="grid-list">
    @for (listItem of items.slice(0, count); track listItem.id) {
      <button mat-raised-button (click)="onClick(listItem)" class="squared deep-flex-1">
        <div class="grid-list-button-item-content">
          <mat-icon class="material-symbols-outlined">{{ listItem.icon ?? 'search' }}</mat-icon>
          <div class="label" [matTooltip]="listItem.label">
            <span>{{ listItem.label }}</span>
          </div>
          <div class="count">
            @if (listItem.count != null) {
              <span>{{ listItem.count.toLocaleString(locale) }}</span>
            }
            <mat-icon class="material-symbols-outlined">chevron_right</mat-icon>
          </div>
        </div>
      </button>
    }

    @if (!loading && items.length === 0) {
      <div class="no-results">
        <span translate="TRANS__WELCOME_SCREEN__NO_RESULTS"></span>
      </div>
    } @else if (items.length > count) {
      <button mat-stroked-button class="show-more" (click)="onShowMore()">
        <span translate="TRANS__WELCOME_SCREEN__SHOW_MORE"></span>
      </button>
    } @else if (count > DEFAULT_NUMBER_OF_LIST_ITEMS) {
      <button mat-stroked-button class="show-less" (click)="onShowLess()">
        <span translate="TRANS__WELCOME_SCREEN__SHOW_LESS"></span>
      </button>
    }
  </div>
</ng-template>

<form #searchForm (submit)="onSearchSubmit($event, searchForm)" class="search-form">
  <mat-form-field appearance="outline" class="bg-white">
    <mat-label>
      @if (museumInstance) {
        {{ 'TRANS__WELCOME_SCREEN__SEARCH_LABEL_MUSEUM' | translate: { name: museumInstance.shortName } }}
      } @else {
        {{ 'TRANS__WELCOME_SCREEN__SEARCH_LABEL' | translate }}
      }
    </mat-label>
    <input matInput type="text" name="query" autocomplete="off">

    <div matSuffix class="end-buttons">
      <button mat-icon-button color="primary" type="submit" aria-label="search">
        <mat-icon class="material-symbols-outlined">search</mat-icon>
      </button>
    </div>
  </mat-form-field>
</form>

<section>
  <div class="flex items-center justify-between gap-4 density-minimum">
    <mat-label>
      {{ 'TRANS__WELCOME_SCREEN__RECENT_EDITS__TITLE' | translate }}
    </mat-label>

    <button mat-button [matMenuTriggerFor]="onlyMyRegistrationsMenu">
      @if (onlyMyOwnRegistrations) {
        {{ 'TRANS__WELCOME_SCREEN__RECENT_EDITS__MY_OBJECTS' | translate }}
      } @else {
        {{ 'TRANS__WELCOME_SCREEN__RECENT_EDITS__ALL_OBJECTS' | translate }}
      }
      <mat-icon class="material-symbols-outlined" iconPositionEnd>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #onlyMyRegistrationsMenu="matMenu">
      <button mat-menu-item (click)="onOnlyMyOwnRecentRegistrationsChange({value: true})">
        {{ 'TRANS__WELCOME_SCREEN__RECENT_EDITS__MY_OBJECTS' | translate }}
      </button>
      <button mat-menu-item (click)="onOnlyMyOwnRecentRegistrationsChange({value: false})">
        {{ 'TRANS__WELCOME_SCREEN__RECENT_EDITS__ALL_OBJECTS' | translate }}
      </button>
    </mat-menu>

  </div>
  <app-recent-registrations-list
    [registrations]="recentRegistrations"
    [loading]="loadingRecentRegistrations"
    (registrationClicked)="onRecentRegistrationClick($event)"
  ></app-recent-registrations-list>
</section>


  <section>
    <mat-label class="flex items-center gap-2">
      {{ 'TRANS__WELCOME_SCREEN__SAVED_SEARCHES_LABEL' | translate }}
      <mat-spinner *ngIf="loadingSavedSearches" diameter="20"></mat-spinner>
    </mat-label>
    @if (!loadingSavedSearches && savedSearches.length === 0) {

      <div class="squared deep-flex-1 missing-saved-search-button">
        <div class="grid-list-button-item-content">
          <mat-icon class="material-symbols-outlined">saved_search</mat-icon>
          <div class="label">
            <span>{{ 'TRANS__WELCOME_SCREEN__NO_SAVED_SEARCHES_LABEL' | translate}}</span>
          </div>
        </div>
      </div>
    } @else {
      <ng-container
        [ngTemplateOutlet]="gridListSmall"
        [ngTemplateOutletContext]="{
                           loading: loadingSavedSearches,
                           items: savedSearches,
                           count: numberOfFocuses(),
                           onClick: onListItemClick.bind(this),
                           onShowMore: onShowMoreFocuses.bind(this),
                           onShowLess: onShowLessFocuses.bind(this) }">
      </ng-container>
    }
  </section>

  <section>
    <mat-label class="flex items-center gap-2">
      {{ 'TRANS__WELCOME_SCREEN__OBJECTS_LABEL' | translate }}
      <mat-spinner *ngIf="loadingObjectList" diameter="20"></mat-spinner>
    </mat-label>
    <ng-container
      [ngTemplateOutlet]="gridListSmall"
      [ngTemplateOutletContext]="{
                       loading: loadingObjectList,
                       items: objectList,
                       count: numberOfArtifacts(),
                       onClick: onListItemClick.bind(this),
                       onShowMore: onShowMoreArtifacts.bind(this),
                       onShowLess: onShowLessArtifacts.bind(this) }">
    </ng-container>
  </section>

<section>
  <mat-label class="flex items-center gap-2">
    {{'TRANS__WELCOME_SCREEN__CATEGORIES_LABEL' | translate}}
    <mat-spinner *ngIf="loadingCategories" diameter="20"></mat-spinner>
  </mat-label>

  <div class="grid-list">
    @for (category of categories; track category.id) {
      <div
        class="card-button tall squared deep-flex-1"
        (click)="onListItemClick(category)"
        tabindex="0"
        role="button"
        matRipple
        [attr.aria-label]="category.label"
      >
        <span class="hover-overlay"></span>
        <div class="category-content">
          <mat-icon class="icon material-symbols-outlined">{{ category.icon ?? 'search' }}</mat-icon>
          <div class="label">
            <span>{{ category.label }}</span>
          </div>
          <div class="count">
            <span>
              @if (category.count === 1) {
                {{ 'TRANS__WELCOME_SCREEN__N_POSTS_SINGULAR' | translate }}
              } @else {
                {{ 'TRANS__WELCOME_SCREEN__N_POSTS' | translate: {n: category.count?.toLocaleString(locale) } }}
              }
            </span>
            <mat-icon class="material-symbols-outlined">chevron_right</mat-icon>
          </div>

          @if (canUseAdvancedSearch && category.searchSuggestions?.length) {

            <button
              mat-button
              class="search-suggestion-trigger density--3"
              [matMenuTriggerFor]="searchSuggestionsMenu"
              (click)="$event.stopPropagation(); $event.preventDefault();"
            >
              {{ 'TRANS__WELCOME_SCREEN__SEARCH_SUGGESTION_TRIGGER_LABEL' | translate }}
              <mat-icon class="material-symbols-outlined" matButtonIcon iconPositionEnd>arrow_drop_down</mat-icon>
            </button>

            <mat-menu #searchSuggestionsMenu="matMenu">

              @for (suggestion of category.searchSuggestions; track suggestion) {
                <button type="button" mat-menu-item (click)="onSearchSuggestionClick($event, suggestion)">
                  {{ suggestion.label }}
                </button>
              }
            </mat-menu>

          } @else {
            <div class="search-suggestion-trigger placeholder"></div>
          }
        </div>
      </div>
    }
    @if (!loadingCategories && categories.length === 0) {
      <div class="no-results">
        <span translate="TRANS__WELCOME_SCREEN__NO_RESULTS"></span>
      </div>
    }
  </div>

</section>

