import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import {DragulaModule} from 'ng2-dragula';

import {MediaCarouselComponent} from './media-carousel/media-carousel.component';
import {ObjectViewModule} from '../object-view/object-view.module';
import {SharedModule} from '../shared/shared.module';
import {ChangeMediaOrderComponent} from './change-media-order/change-media-order.component';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {MatSliderModule} from '@angular/material/slider';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MediaCarouselTopMenuComponent } from './media-carousel-top-menu/media-carousel-top-menu.component';
import { ImageCarouselAnnotationPointsComponent } from './image-carousel-annotation-points/image-carousel-annotation-points.component';
import { ChangeMediaOrderMediaTypeComponent } from './change-media-order-media-type/change-media-order-media-type.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {RouterModule} from '@angular/router';
import {ThumbnailCarouselComponent} from './thumbnail-carousel/thumbnail-carousel.component';
import {
  ThumbnailCarouselNavButtonComponent
} from './thumbnail-carousel-nav-button/thumbnail-carousel-nav-button.component';
import {ThumbnailStackComponent} from './thumbnail-stack/thumbnail-stack.component';
import {MatButtonToggle, MatButtonToggleGroup} from '@angular/material/button-toggle';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
    DragulaModule,
    ObjectViewModule,
    MediaSelectorModule,
    MatSliderModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    DragDropModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule,
    MatButtonToggleGroup,
    MatButtonToggle
  ],
  exports: [
    MediaCarouselComponent,
    ChangeMediaOrderComponent
  ],
  declarations: [
    MediaCarouselComponent,
    ChangeMediaOrderComponent,
    MediaCarouselTopMenuComponent,
    ImageCarouselAnnotationPointsComponent,
    ChangeMediaOrderMediaTypeComponent,
    ThumbnailCarouselComponent,
    ThumbnailCarouselNavButtonComponent,
    ThumbnailStackComponent
  ]
})
export class MediaCarouselModule {
}
