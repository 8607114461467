import { Component } from '@angular/core';

@Component({
  selector: 'app-resize-handle',
  standalone: true,
  imports: [],
  templateUrl: './resize-handle.component.html',
  styleUrl: './resize-handle.component.scss'
})
export class ResizeHandleComponent {

}
