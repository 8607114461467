import {effect, Injectable, signal} from '@angular/core';
import {ListItem, SearchSuggestion} from './ui/welcome-screen-ui.component';
import {CmsApiService} from '../core/cms-api.service';
import {Focus} from '../core/definitions/focus';
import {DashboardListCategory} from '../core/definitions/dashboard-list-category';
import {LoginService} from '../core/login.service';
import {filter, map} from 'rxjs/operators';
import {toSignal} from '@angular/core/rxjs-interop';
import {objectTypeToMatSymbol} from '../shared/material-icons';
import {RecentRegistration} from './ui/recent-registrations-list/recent-registrations-list.component';
import {SearchParameters} from '../core/definitions/search-parameters';
import {SolrFilterService} from '../core/solr-filter.service';
import {SearchService} from '../core/search.service';
import {MediaHelperService} from '../core/media-helper.service';
import {SettingsService} from '../core/settings.service';
import {OperationTarget} from '../core/definitions/operation-target.enum';
import {OperationService} from '../operations/operation.service';
import {OperationDef} from '../core/definitions/operation-def';
import {MetaTypes} from '../core/definitions/meta-types';
import {UserCacheService} from '../core/user-cache.service';
import {AdvancedSearchToolsService} from "../core/advanced-search-tools.service";

export const MAX_NUMBER_OF_RECENT_REGISTRATIONS = 12;

// Usually, a list item corresponds with an object type that is mapped to an icon (objectTypeToMatSymbol),
// but there are some exceptions.
const nameToIcon = new Map <string, string> ([
  ['home', 'home'],
  ['locations', 'pin_drop'],
  ['places', 'location_on'],
  ['spectrum_procedures', 'account_tree'],
  ['admin_events', 'event'],
  ['folders', 'folder'],
  ['named', 'badge'],
  ['media', 'attach_file'],
  ['media_folders', 'perm_media'],
  ['packages', 'inventory_2'],
]);

function findSearchSuggestions(category: DashboardListCategory, allSuggestions: SearchSuggestion[]): SearchSuggestion[] {
  return allSuggestions.filter(suggestion => suggestion.object_types?.includes(category.name))
    .map(suggestion => ({
      path: category.path,
      ...suggestion,
    }));
}

function categoryToListItem(category: DashboardListCategory, withSearchSuggestions: boolean = false, allSuggestions: SearchSuggestion[] = []): ListItem {
  const searchSuggestions = withSearchSuggestions ? findSearchSuggestions(category, allSuggestions) : []
  return {
    ...category,
    icon: nameToIcon.get(category.name) ?? objectTypeToMatSymbol(category.id),
    searchSuggestions,
  }
}

const ADVANCED_SEARCH_USER_RIGHT_ID = "325a89fe-8816-434a-9578-e1155123f8ed";

@Injectable({
  providedIn: 'root'
})
export class WelcomeService {

  savedSearches = signal<ListItem[]>([]);
  loadingSavedSearches = signal(true);
  objectList = signal<ListItem[]>([]);
  loadingObjectList = signal(true);
  categories = signal<ListItem[]>([])
  loadingCategories = signal(true);
  recentRegistrations = signal<RecentRegistration[]>([]);
  loadingRecentRegistrations = signal(true);

  createNewOperations = signal<OperationDef[]>([])

  constructor(
    private cmsApi: CmsApiService,
    private loginService: LoginService,
    private solrFilter: SolrFilterService,
    private searchService: SearchService,
    private mediaHelperService: MediaHelperService,
    private settings: SettingsService,
    private operationService: OperationService,
    private userCacheService: UserCacheService,
    private advancedSearchToolsService: AdvancedSearchToolsService,
  ) { }

  userData$ = this.loginService.currentUser.asObservable();
  canUseAdvancedSearch = toSignal(this.userData$.pipe(
    filter(user => user !== null),
    map(user => user.rights_ids?.[ADVANCED_SEARCH_USER_RIGHT_ID] === true),
  ))

  async init() {
    await Promise.all([
      this.loadFocuses(),
      this.loadCategories(),
      this.loadArtifacts(),
      this.loadRecentRegistrations(),
      this.loadCreateOperations(),
    ])
  }

  // "home" and "home/locations" are exceptions
  private readonly ignoredCategories = new Set(['home', 'home/locations']);
  async loadCategories() {
    this.loadingCategories.set(true);

    const [categories, userData, searchSuggestions] = await Promise.all([
      this.cmsApi.getDashboardCategories('home'),
      this.userCacheService.getUserData(),
      this.advancedSearchToolsService.fetchSearchSuggestions(),
    ]);

    this.categories.set(categories.items
      .filter((category) => !this.ignoredCategories.has(category.path))
      .map(cat => categoryToListItem(cat, userData.is_whitelisted, searchSuggestions))
    );
    this.loadingCategories.set(false);
  }

  async loadArtifacts() {
    this.loadingObjectList.set(true);

    const [suggestions, artifacts, userData] = await Promise.all([
      this.advancedSearchToolsService.fetchSearchSuggestions(),
      this.cmsApi.getDashboardCategories('home/artifacts'),
      this.userCacheService.getUserData(),
    ]);

    this.objectList.set(artifacts.items.map(a => categoryToListItem(a, userData.is_whitelisted, suggestions)));
    this.loadingObjectList.set(false);
  }

  async loadFocuses() {
    this.loadingSavedSearches.set(true);
    const focuses: Array<Focus> = await this.cmsApi.getStoredFocusesForUser();
    this.savedSearches.set(focuses.map(f => ({
      ...f,
      id: f.focusId,
      label: f.focus.name,
      count: undefined,
      icon: 'saved_search',
    })));
    this.loadingSavedSearches.set(false);
  }

  async loadRecentRegistrations(userId?: string) {
    this.loadingRecentRegistrations.set(true);

    const searchParams = {} as SearchParameters;
    searchParams.rows = MAX_NUMBER_OF_RECENT_REGISTRATIONS;
    searchParams.sort = 'updated_at desc';
    this.solrFilter.addFq(searchParams, 'parent_meta_type', MetaTypes.ARTIFACT);

    if (userId) {
      this.solrFilter.addFq(searchParams, 'updated_by_id', userId);
    }

    const searchRes = await this.searchService.search(searchParams);

    const recentRegistrations = await Promise.all(searchRes.artifacts.map(async artifact => {
      let img = '';
      if (artifact.thumbnail_id) {
        img = await this.mediaHelperService.getThumbUrl(artifact, 'thumbnail_id');
      }

      // HACK: All artifacts has their name field polluted with the superobject name as a prefix.
      // To avoid displaying superobject type twice, remove it from the name.
      const [prefix, name] = artifact.artifact_name?.split(`${artifact.superobject_type_id_value}:`, 2);
      const label = name ?? prefix;

      return {
        ...artifact,
        img,
        label,
        type: artifact.superobject_type_id_value,
      } as RecentRegistration;
    }));

    this.recentRegistrations.set(recentRegistrations)
    this.loadingRecentRegistrations.set(false);
  }

  async loadCreateOperations() {
    const operationContainer = await this.operationService.createOperationContainer(OperationTarget.CREATE_OBJECT_VIEW);
    await this.operationService.setOperations(operationContainer);
    this.createNewOperations.set(operationContainer.operations);
  }


}
