import {Component, Signal, ViewChild} from '@angular/core';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import {MatButton, MatFabButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {toSignal} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {CurrentObjectContext} from '../current-object.context';
import {ResizableDirective} from '../../resizable.directive';

@Component({
  selector: 'app-object-page-layout',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatFabButton,
    MatButton,
    MatIcon,
    TranslateModule,
    MatIconButton,
    ResizableDirective,
  ],
  templateUrl: './object-page-layout.component.html',
  styleUrl: './object-page-layout.component.scss'
})
export class ObjectPageLayoutComponent {

  @ViewChild('rightDrawer') rightDrawer: MatSidenav;

  isSmallScreen: Signal<boolean>
  constructor(
    private breakpointObserver: BreakpointObserver,
    private currentObjectContext: CurrentObjectContext,
  ) {
    this.isSmallScreen = toSignal(this.breakpointObserver.observe('(max-width: 1023px)').pipe(map(result => result.matches)));
  }

  get isEditing() {
    return this.currentObjectContext.isEditing;
  }


  openRightDrawer() {
    this.rightDrawer.open('program')
  }

}
