<div class="edit-item">
  <div class="edit-item__header">
        <span class="edit-item__fields-title">
          {{ item.title }}
        </span>
    <button mat-icon-button (click)="removed.emit()">
      <mat-icon>
        remove_circle_outline
      </mat-icon>
    </button>
  </div>

  <div class="edit-item__content">
    <div class="edit-item__fields">
      <span class="edit-item__fields-label">
        {{ 'TRANS__DAMS_TYPE__' + item.type.toUpperCase() | translate }}
      </span>

      @if (suggestion !== null) {
        <div class="edit-item__suggestion-box">
          <span class="edit-item__suggestion-label edit-item__suggestion-label--bold">
            {{ item.created_by }}
          </span>
          <span class="edit-item__suggestion-label edit-item__suggestion-label--grow">
            &nbsp;Funnet navn
          </span>

          <button mat-flat-button class="edit-item__suggestion-button">
            Sett inn i 'Fotograf'
          </button>

          <button mat-icon-button (click)="clearSuggestion()">
            <mat-icon>
              close
            </mat-icon>
          </button>
        </div>
      }

      <span>
        Fotograf og bildetype
      </span>

      <button mat-stroked-button>
        <mat-icon>
          add
        </mat-icon>

        Legg til bildetekst
      </button>

      <span>
        Egenskaper
      </span>

      <span>
        Administrasjon
      </span>

      <mat-checkbox>
        Publiser bildet på DigitaltMuseum???
      </mat-checkbox>

      <button mat-stroked-button>
        <mat-icon>
          add
        </mat-icon>

        Sett publisert???
      </button>

      <span>
        Rettigheter
      </span>
    </div>

    <div class="edit-item__image-name">
      @if (!item.$$hasImage) {
        <div class="edit-item__placeholder">
          <mat-icon class="edit-item__placeholder-icon">
            {{DamsService.getIconFromType(item.type)}}
          </mat-icon>
        </div>
      }
      @else {
        <img
          [alt]="item.title"
          class="edit-item__image"
          [src]="image?.medium"
        />
      }
    </div>
  </div>
</div>
