import {Component, forwardRef, inject, InjectionToken, Injector, OnDestroy, Optional, signal} from '@angular/core';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {ObjectPageLayoutComponent} from './object-page-layout/object-page-layout.component';
import {ActivatedRoute, Navigation, NavigationEnd, Router, RouterModule, RouterOutlet} from '@angular/router';
import {
  NavMenuItem,
  ObjectPageNavigationComponent,
} from './ui/object-page-navigation/object-page-navigation.component';
import {CurrentObjectContext} from './current-object.context';
import {ObjectPageV2OverviewComponent} from './object-page-v2-overview/object-page-v2-overview.component';
import {iconToMaterialSymbol, objectTypeToMatSymbol} from '../shared/material-icons';
import {OperationDef} from '../core/definitions/operation-def';
import {ActionMenuItem} from './ui/object-page-header/menu-group-button/menu-group-button.component';
import {MediaHelperService} from '../core/media-helper.service';
import {SharedModule} from '../shared/shared.module';
import {ContentMenu} from '../core/definitions/object-content-tab/content-menus';
import {TranslateService} from '@ngx-translate/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {OperationDialogService} from '../operations/operation-dialog.service';
import {OperationService} from '../operations/operation.service';
import {Subscription} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import {MediaCarouselModule} from '../media-carousel/media-carousel.module';
import {M3GlobalKplStylesComponent} from '../m3-global-kpl-styles/m3-global-kpl-styles.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {CurrentSearchContext} from '../object-search/current-search.context';
import {
  SelectObjectTemplateButtonComponent
} from '../select-object-template-button/select-object-template-button.component';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {SearchSelectorService} from '../core/search-selector.service';
import {SelectorContainer} from '../core/definitions/selector-container';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {ObjectPageHeadingComponent} from './ui/object-page-header/object-page-heading/object-page-heading.component';
import {
  ObjectPageOperationsToolbarComponent
} from './ui/object-page-header/object-page-operations-toolbar/object-page-operations-toolbar.component';
import {
  ObjectPageSearchNavigationComponent
} from './ui/object-page-header/object-page-search-navigation/object-page-search-navigation.component';
import {ObjectOverviewFieldsV2Component} from './ui/object-overview-fields-v2/object-overview-fields-v2.component';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {FeatureFlagsService} from '../core/feature-flags.service';
import {RefData, RefParams, RefService, StateParams} from '../core/ref.service';
import {
  FloatingFeatureToggleSwitchComponent
} from '../floating-feature-toggle-switch/floating-feature-toggle-switch.component';

export const ARTIFACT_ID = new InjectionToken<string>("ARTIFACT_ID")

type ActionMenuAndOperationDef = ActionMenuItem & OperationDef
const EDIT_OBJECT_OPERATION_TYPE_ID = 'a4afc969-a015-4648-81b5-eee5a477461c';

function operationToActionMenu(op: OperationDef): ActionMenuAndOperationDef {
  if (op.operation_type === 'operation') {
    return {
      ...op,
      type: 'action',
      label: op.menu_title,
      id: op.menu_title,
      icon: iconToMaterialSymbol(op.menu_icon),
    }
  } else if (op.operation_type === 'group') {
    return {
      ...op,
      type: 'group',
      label: op.menu_title,
      id: op.menu_title,
      icon: iconToMaterialSymbol(op.menu_icon),
      children: op.sub_operations?.map(operationToActionMenu)
    }
  }
}

@Component({
  selector: 'app-object-page-v2',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    ObjectPageLayoutComponent,
    RouterOutlet,
    MatIconButton,
    RouterModule,
    ObjectPageNavigationComponent,
    ObjectPageV2OverviewComponent,
    SharedModule,
    MediaCarouselModule,
    M3GlobalKplStylesComponent,
    OverviewFieldsModule,
    SelectObjectTemplateButtonComponent,
    MatProgressSpinner,
    forwardRef(() => ObjectSearchModule),
    ObjectPageHeadingComponent,
    ObjectPageOperationsToolbarComponent,
    ObjectPageSearchNavigationComponent,
    ObjectOverviewFieldsV2Component,
    MatSlideToggle,
    FloatingFeatureToggleSwitchComponent,
  ],
  templateUrl: './object-page-v2.component.html',
  styleUrl: './object-page-v2.component.scss'
})
export class ObjectPageV2Component implements OnDestroy {

  toggleAnnotations = false;
  imageFullScreen = false;
  modelFullscreen = false;

  private refererRoute?: Navigation;
  selectorContainer = signal<SelectorContainer | null>(null);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private currentObjectContext: CurrentObjectContext,
    private mediaService: MediaHelperService,
    private translate: TranslateService,
    private operationDialogService: OperationDialogService,
    private operationService: OperationService,
    private seachSelectorService: SearchSelectorService,
    private ref: RefService,
    @Optional() public currentSearchContext?: CurrentSearchContext,
  ) {

    this.refererRoute = this.router.getCurrentNavigation()?.previousNavigation;

    this.subscriptions.push(this.route.paramMap.subscribe((p) => {
      this.headerActions = [];
      this.currentObjectContext.init(p.get('artifactId')).then(() => {
        this.setThumbnail();
      })
    }),
      this.currentObjectContext.menusChanged$.subscribe(() => {
        this.updateUIElements();
      }),
      this.seachSelectorService.searchSelectorChanged$.subscribe((selectionContainer) => {
        this.selectorContainer.set(selectionContainer);
      }),
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      ).subscribe(() => {
        this.isOverviewPage.set(this.route.firstChild.routeConfig.path === 'overview');
      }));

    this.subscriptions.push(this.route.queryParamMap.subscribe((params) => {
      if (params.has('edit')) {
        this.currentObjectContext.isEditing = params.get('edit') === 'true'
        this.updateUIElements()
      }
      if (params.has('template_group_id')) {
        this.currentObjectContext.onChangeTemplateGroup(params.get('template_group_id'));
      } else {
        this.currentObjectContext.onChangeTemplateGroup(null);
      }
    }))

    this.subscriptions.push(this.operationService.operationStepChange$.pipe(
      debounceTime(200)
    ).subscribe((event) => {

      if (event === 'CANCEL_EDIT') {
        this.currentObjectContext.isEditing = false;
        this.router.navigate(['.'], {
          relativeTo: this.route,
          queryParams: {
            edit: null
          },
          queryParamsHandling: 'merge'
        }).then(() => {
          this.updateUIElements();
        })
      } else {
        const { step, result } = event

        if (!step) {
          this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParamsHandling: 'preserve'
          })
        }

        if (step?.change_state?.length) {
          const [state] = step.change_state
          const subPage = state.state_params?.listName ?? '.'
          this.refreshObject({});
          // navigate to the child route
          this.router.navigate([subPage], {
            relativeTo: this.route,
            queryParams: state.state_params || {},
            queryParamsHandling: 'merge',
            replaceUrl: true,
          }).then(() => {
            this.updateUIElements();
          })
        }
      }
    }))
  }

  private subscriptions: Subscription[] = [];
  private injector = inject(Injector);

  headerActions: ActionMenuItem[] = [];
  initialActiveChild = this.route.snapshot.firstChild.url.join('/');
  isOverviewPage = signal(this.route.firstChild?.routeConfig.path === 'overview');

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  get artifact() {
    return this.currentObjectContext.art
  }

  get contentInfo() {
    return this.currentObjectContext.contentInfo
  }

  get isEditing() {
    return this.currentObjectContext.isEditing
  }

  get operationContainer() {
    return this.currentObjectContext.operationContainer
  }

  get templateSelectorContainer() {
    return this.currentObjectContext.templateSelectorContainer
  }

  get sectionsContainer() {
    return this.currentObjectContext.sectionsContainer
  }

  get curAnn() {
    return this.currentObjectContext.currAnn
  }

  get objectView() {
    return this.currentObjectContext.sectionsContainer?.objectView
  }

  get loadingNavigationItems() {
    return this.currentObjectContext.loadingOperations
  }

  get loadingArtifact() {
    return this.currentObjectContext.loadingArtifact;
  }

  get loadingMedia() {
    return this.loadingArtifact || this.currentObjectContext.loadingMedia;
  }


  onTemplateSelectorCreated($event) {
    // console.log('ObjectPageLayoutComponent', this.layoutComponent)
    // this.layoutComponent.openRightDrawer()
    this.currentObjectContext.onTemplateSelectorCreated($event)
  }
  onChangeTemplateGroup(newGroupId: string){
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        template_group_id: newGroupId
      },
      queryParamsHandling: 'merge'
    })
  }
  thumbnailUrl = signal('')
  placeholderThumbnailIcon = signal('');

  navigationItems: NavMenuItem[] = [];

  operationClick(operation: ActionMenuAndOperationDef) {

    this.currentObjectContext.setCurrentOperation(operation);
    if (operation.operation_type_id === EDIT_OBJECT_OPERATION_TYPE_ID) {
      this.operationService.goNextOperationStep(this.currentObjectContext.operationContainer);

      this.router.navigate(['overview'], {
        relativeTo: this.route,
        queryParams: {
          edit: true
        },
        queryParamsHandling: 'merge'
      })

      this.currentObjectContext.isEditing = true;
      this.updateUIElements();
      return
    }

    this.operationDialogService.openOperationDialog(this.currentObjectContext.operationContainer)
      .then((result) => {
        this.currentObjectContext.objectRefresh({})
      });
  }

  refreshObject($event: any) {
    console.log('obj refresh: ', $event);
    this.currentObjectContext.objectRefresh($event)
  }

  openImageFullScreen(activeImage: any) {
    this.toggleAnnotations = activeImage.toggleAnnotations;
    this.imageFullScreen = true;
  }

  openModelFullscreen(activeModel: any) {
    this.toggleAnnotations = false;
    this.modelFullscreen = true;
  }

  closeImageFullScreen() {
    this.imageFullScreen = false;
  }

  closeModelFullscreen() {
    this.modelFullscreen = false;
  }

  private setThumbnail() {
    if (this.artifact) {
      this.placeholderThumbnailIcon.set(objectTypeToMatSymbol(this.artifact.object_type) ?? 'image');
      if (this.artifact.images?.length) {
        const imgId = this.artifact.images[0].image_id
        this.mediaService.getImageUrlsFromImageIds([imgId], ['thumbnail']).then((urls) => {
          this.thumbnailUrl.set(urls[imgId]['thumbnail'])
        });
      } else {
        this.thumbnailUrl.set('')
      }
    }
  }

  private contentMenuToNavMenuItem(contentMenu: ContentMenu): NavMenuItem {
    if (contentMenu.sub_menus?.length) {
      return {
        type: 'section',
        label: toSignal(this.translate.get(contentMenu.caption), { injector: this.injector }),
        disabled: this.currentObjectContext.isEditing,
        icon: iconToMaterialSymbol(contentMenu.icon) ?? getNavigationLinkIcon(contentMenu.content_list),
        children: contentMenu.sub_menus.map(this.contentMenuToNavMenuItem.bind(this)),
        count: contentMenu.count || contentMenu.sub_menus.reduce((acc, subMenu) => acc + (subMenu.count || 0), 0)
      }
    }
    return {
      type: 'link',
      label: toSignal(this.translate.get(contentMenu.caption), { injector: this.injector }),
      disabled: this.currentObjectContext.isEditing,
      link: contentMenu.content_list,
      icon: iconToMaterialSymbol(contentMenu.icon) ?? getNavigationLinkIcon(contentMenu.content_list),
      count: contentMenu.count,
    }
  }

  async onBackClick() {
    const currentIdx = this.currentSearchContext?.findResultIndex(this.artifact.artifact_id);
    if (currentIdx > 0) {
      const prevArtifact = this.currentSearchContext?.searchResults?.[currentIdx - 1];
      const params = new RefParams(prevArtifact, {
        listName: null
      } as StateParams);
      params.searchItemIndex = currentIdx - 1;
      params.rootObjId = prevArtifact.artifact_id;
      const refData = await this.ref.makeRef(params);
      if (refData.stateName.endsWith('artifact')) {
        this.goToArtifact(prevArtifact.artifact_id)
      } else {
        this.goToNotArtifact(refData)
      }
    }
  }

  async onForwardClick() {
    const currentIdx = this.currentSearchContext?.findResultIndex(this.artifact.artifact_id);
    if (currentIdx < this.currentSearchContext?.resultSize - 1) {
      const nextArtifact = this.currentSearchContext?.searchResults?.[currentIdx + 1];
      const params = new RefParams(nextArtifact, {
        listName: null
      } as StateParams);
      params.searchItemIndex = currentIdx + 1;
      params.rootObjId = nextArtifact.artifact_id;
      const refData = await this.ref.makeRef(params);
      if (refData.stateName.endsWith('artifact')) {
        this.goToArtifact(nextArtifact.artifact_id)
      } else {
        this.goToNotArtifact(refData)
      }
    }
  }

  onCloseClick() {
    if (this.currentSearchContext) {
      this.router.navigate(['/search'], {
        relativeTo: this.route,
        queryParams: {
          artifactId: null,
        },
        queryParamsHandling: 'merge'
      })
    } else if(this.refererRoute) {
      this.router.navigateByUrl(this.refererRoute.finalUrl)
    } else {
      this.router.navigate(['/'])
    }
  }

  private goToArtifact(artifactId: string) {
    this.router.navigate(['..', artifactId], {
      relativeTo: this.route,
      queryParams: {
        artifactId
      },
      queryParamsHandling: 'merge'
    })
  }

  private goToNotArtifact(refData: RefData) {
    const link = [...refData.routerLink.map(l => l.replaceAll('/', ''))]
    if (this.currentSearchContext) {
      link.unshift('search')
    }
    link.unshift('/')
    this.router.navigate(link, {
      queryParams: refData.queryParams,
      queryParamsHandling: 'merge'
    })
  }

  private updateUIElements() {
    this.headerActions = this.currentObjectContext.operationContainer?.operations?.map(operationToActionMenu) ?? []
    this.navigationItems = [
      ...this.currentObjectContext.menus.map(this.contentMenuToNavMenuItem.bind(this)),
      {
        type: 'group',
        label: toSignal(this.translate.get('TRANS__OBJECT_INFORMATION_TABS__CHANGES'), { injector: this.injector }),
        disabled: this.currentObjectContext.isEditing,
        link: 'changelog',
        icon: 'history',
        children: [
          {
            type: 'link',
            label: toSignal(this.translate.get('TRANS__OBJECT_INFORMATION_TABS__FOLDERS'), { injector: this.injector }),
            disabled: this.currentObjectContext.isEditing,
            link: 'changelog/folders',
          },
          {
            type: 'link',
            label: toSignal(this.translate.get('TRANS__OBJECT_INFORMATION_TABS__CHANGES'), { injector: this.injector }),
            disabled: this.currentObjectContext.isEditing,
            link: 'changelog/changes',
          },
          {
            type: 'link',
            label: toSignal(this.translate.get('TRANS__OBJECT_INFORMATION_TABS__IDENTIFICATION_NUMBER_LOG'), { injector: this.injector }),
            disabled: this.currentObjectContext.isEditing,
            link: 'changelog/id-numbers',
          },
          {
            type: 'link',
            label: toSignal(this.translate.get('TRANS__OBJECT_INFORMATION_TABS__COLLECTION_LOG'), { injector: this.injector }),
            disabled: this.currentObjectContext.isEditing,
            link: 'changelog/collection-log',
          },
          {
            type: 'link',
            label: toSignal(this.translate.get('TRANS__OBJECT_INFORMATION_TABS__COLLECTION_STATUS_LOG'), { injector: this.injector }),
            disabled: this.currentObjectContext.isEditing,
            link: 'changelog/collection-status-log',
          },
          {
            type: 'link',
            label: toSignal(this.translate.get('TRANS__OBJECT_INFORMATION_TABS__QUANTITY_LOG'), { injector: this.injector }),
            disabled: this.currentObjectContext.isEditing,
            link: 'changelog/quantity-log',
          },
        ]
      }
    ] as NavMenuItem[]
  }

  navigateToOldDesign() {
    // use a small timeout to allow the slider animation to finish
    setTimeout(() => {
      if (this.currentSearchContext) {
        this.router.navigate(['/search/artifact'], {
          queryParamsHandling: 'merge'
        })
      } else {
        this.router.navigate(['/artifact'], {
          queryParamsHandling: 'merge'
        })
      }
    }, 200)
  }
}

/**
 * Menu icons are usually defined in the API. To prevent breaking changes there for the old object page,
 * we need to map the old icon names to the new material icons.
 * @param name name of the navigation item/content list
 */
function getNavigationLinkIcon(name: string): string {

  switch (name) {
    case 'overview':
      return 'list_alt';
    case 'relations':
      return 'device_hub';
    case 'uploads':
      return 'attach_file';
    case 'conservation':
      return 'format_paint';
    case 'information':
      return 'info';
    case 'activities':
      return 'task';
    case 'transference':
      return 'my_location';
    case 'spectrum_procedures':
    case 'spectrum_procedure':
      return 'timeline';
  }

  return 'category';

}
